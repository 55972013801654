import React, { useContext } from 'react';
import { UALContext } from 'ual-reactjs-renderer';
import { toast } from 'react-toastify';
import { useApp } from '../../Data/AppContext';

import {
    fetchNotStakedItems,
    unstakeItems,
    fetchTransactionHistory,
    fetchDmpBalance,
} from '../../Services';
import {
    STAKING_DURATION_1_DAY,
    STAKING_DURATION_FIXED,
} from '../../Constants';
import { Button } from '../Ui/v2/Button';

const UnstakeModal = ({ hidePopupHandler }) => {
    const { activeUser } = useContext(UALContext);

    const {
        stakingConfig,
        miningMultiplier,
        setTransactionInfo,
        setUserNotStakedItems,
        selectedItemsForUnstake,
        setSelectedItemsForUnstake,
        removeItemsFromUserStakedItems,
        setDmpBalance,
        setHasNtt,
    } = useApp();

    const handleUnstake = () => {
        if (!selectedItemsForUnstake.length) {
            toast.warning('Select items for unstake');

            return hidePopupHandler();
        }

        unstakeItems({
            activeUser,
            preLaunchItems: selectedItemsForUnstake
                .filter(
                    ({ stakingInfo: { stakingDuration } }) =>
                        stakingDuration === STAKING_DURATION_FIXED
                )
                .map(({ asset_id }) => asset_id),
            dailyItems: selectedItemsForUnstake
                .filter(
                    ({ stakingInfo: { stakingDuration } }) =>
                        stakingDuration === STAKING_DURATION_1_DAY
                )
                .map(({ asset_id }) => asset_id),
        })
            .then(() => {
                setTimeout(() => {
                    fetchNotStakedItems({
                        account: activeUser.accountName,
                        miningMultiplier,
                        stakingConfig,
                    })
                        .then((items) => {
                            setHasNtt(
                                items.some(
                                    ({ schema: { schema_name } }) =>
                                        schema_name === 'stickers'
                                )
                            );

                            setUserNotStakedItems(
                                items.filter(({ schema: { schema_name } }) =>
                                    [
                                        'equipment',
                                        'areas',
                                        'structures',
                                    ].includes(schema_name)
                                )
                            );
                        })
                        .catch((e) => console.log(e));
                }, 2000);

                fetchTransactionHistory({ account: activeUser.accountName })
                    .then((items) => setTransactionInfo(items))
                    .catch((e) => console.log(e));

                removeItemsFromUserStakedItems(selectedItemsForUnstake);
                setSelectedItemsForUnstake([]);

                toast.success('Unstaked');
            })
            .then(() => {
                fetchDmpBalance({ account: activeUser.accountName })
                    .then((balance) => setDmpBalance(balance))
                    .catch((e) => console.log(e));
            })
            .catch((e) => toast.error(e.message))
            .finally(() => hidePopupHandler());
    };

    return (
        <div className="content_block unstake_modal">
            <div className="content_block">
                <div className="title_block">Unstake confirmation</div>
                <div className="title">
                    Are you sure you want to <b>unstake</b> and collect DMP?
                </div>
                <div className="name">NFTs selected:</div>
                <div className="value">{selectedItemsForUnstake.length}</div>
                <div className="button_blocks">
                    <Button size="l" onClick={handleUnstake}>
                        Yes
                    </Button>
                    <Button size="l" use="secondary" onClick={hidePopupHandler}>
                        No
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default UnstakeModal;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useApp } from '../../Data/AppContext';

import Caption from '../../Components/Ui/Caption/Caption';

import {
    ContentContainer,
    StatusBarStaking,
    CardList,
    Sidebar,
} from '../../Components';

import {
    STAKE_PAGE,
    STAKING_LIMIT,
    UNSTAKE_PAGE,
    STAKING_DURATION_1_DAY,
} from '../../Constants';
import { Button } from '../../Components/Ui/v2/Button';
import { useAffiliate } from '../../Hooks/useModal';

const filterAvailableItemsToUnstake = (
    stakingList,
    preLaunchFinishTimestamp
) => {
    return stakingList
        .filter(({ stakingInfo }) => {
            if (stakingInfo.stakingDuration === STAKING_DURATION_1_DAY) {
                return (
                    new Date(
                        stakingInfo.time * 1000 + 60 * 60 * 24 * 1000
                    ).getTime() -
                        Date.now() <=
                    0
                );
            }

            return preLaunchFinishTimestamp - Date.now() <= 0;
        })
        .slice(0, STAKING_LIMIT);
};

const NFTStakingPage = () => {
    const {
        sidebar,
        stakedList,
        stakedListFetched,
        unstakedList,
        unstakedListFetched,
        isAuthenticated,
        showPopupHandler,
        selectedItemsForStake,
        setSelectedItemsForStake,
        selectedItemsForUnstake,
        setSelectedItemsForUnstake,
        toggleStakingPopupHandler,
        preLaunchFinishTimestamp,
    } = useApp();
    const { showAffiliationPopup } = useAffiliate();

    useEffect(() => {
        showAffiliationPopup('stacking-is-over');
    }, []);
    const showStakingInfo = () => {
        toggleStakingPopupHandler(true);
    };

    const handleCollect = () => {
        showPopupHandler('claim');
    };

    const [page, setPage] = useState(UNSTAKE_PAGE);
    const [initPageSetup, setInitPageSetup] = useState(false);

    useEffect(() => {
        if (!initPageSetup) {
            if (unstakedListFetched && stakedListFetched && stakedList.length) {
                setPage(UNSTAKE_PAGE);
                setInitPageSetup(true);

                return;
            }

            if (
                stakedListFetched &&
                unstakedListFetched &&
                unstakedList.length
            ) {
                setPage(STAKE_PAGE);
                setInitPageSetup(true);
            }
        }
    }, [
        initPageSetup,
        stakedList,
        stakedListFetched,
        unstakedList,
        unstakedListFetched,
    ]);

    const selectCard = (item) => {
        const index = selectedItemsForStake.findIndex(
            ({ asset_id }) => asset_id === item.asset_id
        );

        if (index !== -1)
            return setSelectedItemsForStake(
                selectedItemsForStake.filter(
                    ({ asset_id }) => asset_id !== item.asset_id
                )
            );

        setSelectedItemsForStake([...selectedItemsForStake, item]);
    };

    const selectStakeCard = (item) => {
        const index = selectedItemsForUnstake.findIndex(
            ({ asset_id }) => asset_id === item.asset_id
        );

        if (index !== -1)
            return setSelectedItemsForUnstake(
                selectedItemsForUnstake.filter(
                    ({ asset_id }) => asset_id !== item.asset_id
                )
            );

        setSelectedItemsForUnstake([...selectedItemsForUnstake, item]);
    };

    const getStakeOrUnstakeBtnText = () => {
        switch (page) {
            case STAKE_PAGE:
                return 'Stake Selected';

            case UNSTAKE_PAGE:
                return 'Unstake Selected';

            default:
                return '';
        }
    };

    const getStakeOrUnstakeBtnHandler = () => {
        switch (page) {
            case STAKE_PAGE:
                return () => {
                    if (selectedItemsForStake.length === 0)
                        setSelectedItemsForStake(
                            unstakedList.slice(0, STAKING_LIMIT)
                        );

                    showPopupHandler('staking');
                };

            case UNSTAKE_PAGE:
                return () => {
                    const itemsToUnstake = filterAvailableItemsToUnstake(
                        selectedItemsForUnstake.length === 0
                            ? stakedList
                            : selectedItemsForUnstake,
                        preLaunchFinishTimestamp
                    );

                    setSelectedItemsForUnstake(itemsToUnstake);

                    if (!itemsToUnstake.length) {
                        return toast.warning('No items to unstake');
                    }

                    showPopupHandler('unstake');
                };

            default:
                return () => {};
        }
    };

    const stakeOrUnstakeBtnText = getStakeOrUnstakeBtnText();
    const stakeOrUnstakeBtnHandler = getStakeOrUnstakeBtnHandler();

    return (
        <>
            <ContentContainer>
                <div className="nft_page auth">
                    <Caption isAuthenticated={isAuthenticated}>
                        NFT Staking
                    </Caption>

                    <div className="nft_page__mobile-buttons">
                        <Button use="secondary" onClick={showStakingInfo}>
                            Staking Info
                        </Button>
                        <Button use="secondary" onClick={handleCollect}>
                            Collect
                        </Button>
                    </div>

                    <div className="nft_page__content">
                        <StatusBarStaking
                            type="nft"
                            setPage={setPage}
                            page={page}
                        />

                        <CardList
                            pageType="Staked NFTs"
                            pageTypeSecond="Non-staked NFTs"
                            stakeOrUnstakeBtnText={stakeOrUnstakeBtnText}
                            stakeOrUnstakeBtnHandler={stakeOrUnstakeBtnHandler}
                            selectedCards={selectedItemsForStake}
                            selectCard={selectCard}
                            selectedUnstekedCards={selectedItemsForUnstake}
                            setSelectUnstekedCard={selectStakeCard}
                            type="nft"
                            page={page}
                            setPage={setPage}
                        />
                    </div>
                </div>
            </ContentContainer>

            {sidebar && <Sidebar />}
        </>
    );
};

export default NFTStakingPage;

import React, { useMemo } from 'react';

import { SidebarRarityMarkers } from '../../../Components/SidebarRarityMarkers';
import { Divider } from '../../../Components/Ui/v2/Divider';
import { ReactComponent as WaxIcon } from '../../../assets/img/icons/wax_icon_orange.svg';
import { ReactComponent as DmpIcon } from '../../../assets/img/icons/dmp_icon.svg';
import { AuctionSidebarContainer } from './AuctionSidebarContainer';
import { isAuctionFinished } from '../utils';
import { formatNumber, getDmpCount, getWaxCount } from '../../../Helpers';
import { TOKEN_TYPE } from '../../../Constants';

export const AllAuctionStatsSidebar = ({ data = [] }) => {
    const totalItemsCount = data.length;

    const classicalCount = useMemo(
        () => data.filter((asset) => asset.auction_type === 'classic').length,
        [data]
    );

    const rarities = data.reduce(
        (result, asset) => {
            result[asset.rarity] += 1;

            return result;
        },
        { common: 0, uncommon: 0, rare: 0, epic: 0, legendary: 0 }
    );

    const { totalWax, totalDmp } = useMemo(
        () =>
            data.reduce(
                (result, asset) => {
                    if (isAuctionFinished(asset)) {
                        result[
                            asset.price_type === TOKEN_TYPE.WAX
                                ? 'totalWax'
                                : 'totalDmp'
                        ] += +asset.current_price;
                    }
                    return result;
                },
                { totalWax: 0, totalDmp: 0 }
            ),
        [data]
    );

    return (
        <AuctionSidebarContainer>
            <p className="auction-page-sidebar-stats__title">
                All Auction Stats
            </p>
            <Divider style={{ margin: '25px 0 12px' }} />
            <div className="auction-page-sidebar-stats__item">
                <span>Total auction items</span>
                <b>{totalItemsCount}</b>
            </div>
            <div
                className="auction-page-sidebar-stats__item"
                style={{ marginTop: 12 }}
            >
                <span>Classical auctions</span>
                <b>{classicalCount}</b>
            </div>
            <Divider style={{ margin: '12px 0' }} />
            <SidebarRarityMarkers
                {...rarities}
                className="auction-page-sidebar-stats__rarities"
            />
            <p className="auction-page-sidebar-stats__title">
                Total winning bids
            </p>
            <Divider style={{ margin: '6px 0 12px' }} />
            <div className="auction-page-sidebar-stats__total">
                <DmpIcon className="auction-page-sidebar-stats__total-icon" />
                <span>{formatNumber(getDmpCount(totalDmp))}DMP</span>
                <WaxIcon className="auction-page-sidebar-stats__total-icon" />
                <span>{formatNumber(getWaxCount(totalWax))} WAX</span>
            </div>
        </AuctionSidebarContainer>
    );
};

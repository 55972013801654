import React, { useContext, useEffect, useState } from 'react';

import { UALContext } from 'ual-reactjs-renderer';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import cn from 'classnames';
import { useApp } from '../../Data/AppContext';
import { useRoutes } from '../../Hooks/Routes';

import { Modal, StakingModal } from '../../Components';

import {
    fetchDmpBalance,
    fetchNotStakedItems,
    fetchStakedItems,
    fetchWaxBalance,
    fetchTransactionHistory,
    fetchStakingConfig,
    fetchPreLaunchFinishTimestamp,
    fetchMiningMultiplier,
    affiliateAuth,
    getAffiliateAchievements,
    discordAuth,
    getDiscordCode,
    getIsAffiliatePath,
} from '../../Services';
import AffiliateModal from '../../Components/Modal/AffiliateModal/AffiliateModal';
import { useAffiliate } from '../../Hooks/useModal';

const AppContent = () => {
    const routes = useRoutes();

    const {
        isAuthenticated,
        sidebar,
        userData,
        shelter,
        toggleRareFilter,
        toggleRareFilterHandler,
        toggleTermFilter,
        toggleTermFilterHandler,
        userLoginHandler,
        setUserDataHandler,
        setUserNotStakedItems,
        unstakedListFetched,
        setUserStakedItems,
        stakedListFetched,
        setWaxBalance,
        waxBalanceFetched,
        setDmpBalance,
        dmpBalanceFetched,
        setTransactionInfo,
        transactionInfoFetched,
        setStakingConfig,
        stakingConfig,
        stakingConfigFetched,
        setUserItemsFetched,
        userItemsFetched,
        preLaunchFinishTimestamp,
        showWelcome,
        setPreLaunchFinishTimestamp,
        preLaunchFinishTimestampFetched,
        miningMultiplier,
        miningMultiplierFetched,
        setMiningMultiplier,
        setAuthData,
        setAffiliateAchievements,
        setHasNtt,
    } = useApp();

    const { activeUser } = useContext(UALContext);
    const history = useHistory();
    const { showAffiliationPopup } = useAffiliate();
    const [miningMultiplierLoading, setMiningMultiplierLoading] =
        useState(false);
    const [notStakedItemsLoading, setNotStakedItemsLoading] = useState(false);
    const [discordCode] = useState(getDiscordCode(window.location.search));
    const [isAffiliatePath] = useState(getIsAffiliatePath(window.location));
    const [stakedItemsLoading, setStakedItemsLoading] = useState(false);
    const [waxBalanceLoading, setWaxBalanceLoading] = useState(false);
    const [dmpBalanceLoading, setDmpBalanceLoading] = useState(false);
    const [transactionInfoLoading, setTransactionInfoLoading] = useState(false);
    const [stakingConfigLoading, setStakingConfigLoading] = useState(false);
    const [
        preLaunchFinishTimestampLoading,
        setPreLaunchFinishTimestampLoading,
    ] = useState(false);

    useEffect(() => {
        if (
            activeUser?.accountName &&
            setUserDataHandler &&
            userLoginHandler &&
            !isAuthenticated
        ) {
            setUserDataHandler(activeUser);
            userLoginHandler();
        }
    }, [activeUser, setUserDataHandler, userLoginHandler, isAuthenticated]);

    const updateAffiliateData = async () => {
        const authData = await affiliateAuth(activeUser.accountName);
        if (authData) setAuthData(authData);

        const achievements = await getAffiliateAchievements();
        setAffiliateAchievements(achievements);
    };

    const parseDiscordCode = async () => {
        showAffiliationPopup('discord');
        try {
            await discordAuth(discordCode);
            showAffiliationPopup('achievement-newcomer');
        } catch (e) {
            console.log(e);
        }
    };

    const handleOnMount = async () => {
        if (activeUser?.accountName) {
            await updateAffiliateData();
            if (discordCode) {
                await parseDiscordCode();
                await updateAffiliateData();
            }
        }
    };

    useEffect(() => {
        if (isAffiliatePath) {
            history.push('/Affiliate');
        }
    }, [isAffiliatePath]);

    useEffect(() => {
        handleOnMount();
    }, [activeUser]);

    useEffect(() => {
        if (
            !miningMultiplierLoading &&
            !miningMultiplierFetched &&
            setMiningMultiplier
        ) {
            setMiningMultiplierLoading(true);

            fetchMiningMultiplier()
                .then((data) => setMiningMultiplier(data))
                .catch((e) => {
                    toast.error(e.message);

                    setMiningMultiplier(1);
                })
                .finally(() => setMiningMultiplierLoading(false));
        }
    }, [miningMultiplierLoading, miningMultiplierFetched, setMiningMultiplier]);

    useEffect(() => {
        if (
            activeUser &&
            activeUser.accountName &&
            !unstakedListFetched &&
            setUserNotStakedItems &&
            !notStakedItemsLoading &&
            stakingConfigFetched &&
            miningMultiplierFetched
        ) {
            setNotStakedItemsLoading(true);

            fetchNotStakedItems({
                account: activeUser.accountName,
                miningMultiplier,
                stakingConfig,
            })
                .then((items) => {
                    setHasNtt(
                        items.some(
                            ({ schema: { schema_name } }) =>
                                schema_name === 'stickers'
                        )
                    );

                    setUserNotStakedItems(
                        items.filter(({ schema: { schema_name } }) =>
                            [
                                'equipment',
                                'areas',
                                'structures',
                                'modules',
                            ].includes(schema_name)
                        )
                    );
                })
                .catch((e) => {
                    toast.error(e.message);

                    setUserNotStakedItems([]);
                })
                .finally(() => setNotStakedItemsLoading(false));
        }
    }, [
        activeUser,
        setUserNotStakedItems,
        unstakedListFetched,
        stakingConfig,
        notStakedItemsLoading,
        stakingConfigFetched,
        miningMultiplierFetched,
        miningMultiplier,
    ]);

    useEffect(() => {
        if (
            activeUser &&
            activeUser.accountName &&
            !stakedListFetched &&
            setUserStakedItems &&
            !stakedItemsLoading &&
            stakingConfigFetched &&
            miningMultiplierFetched
        ) {
            setStakedItemsLoading(true);

            fetchStakedItems({
                account: activeUser.accountName,
                miningMultiplier,
                stakingConfig,
                preLaunchFinishTimestamp,
            })
                .then((items) => setUserStakedItems(items))
                .catch((e) => {
                    toast.error(e.message);

                    setUserStakedItems([]);
                })
                .finally(() => setStakedItemsLoading(false));
        }
    }, [
        activeUser,
        stakedListFetched,
        setUserStakedItems,
        stakedItemsLoading,
        stakingConfig,
        stakingConfigFetched,
        miningMultiplierFetched,
        miningMultiplier,
        preLaunchFinishTimestamp,
    ]);

    useEffect(() => {
        if (
            unstakedListFetched &&
            stakedListFetched &&
            !userItemsFetched &&
            setUserItemsFetched
        ) {
            setUserItemsFetched(true);
        }
    }, [
        unstakedListFetched,
        stakedListFetched,
        userItemsFetched,
        setUserItemsFetched,
    ]);

    useEffect(() => {
        if (
            activeUser &&
            activeUser.accountName &&
            setWaxBalance &&
            !waxBalanceLoading &&
            !waxBalanceFetched
        ) {
            setWaxBalanceLoading(true);

            fetchWaxBalance({ account: activeUser.accountName })
                .then((balance) => setWaxBalance(balance))
                .catch((e) => {
                    setWaxBalance(0);

                    toast.error(e.message);
                })
                .finally(() => setWaxBalanceLoading(false));
        }
    }, [activeUser, waxBalanceLoading, setWaxBalance, waxBalanceFetched]);

    useEffect(() => {
        if (
            activeUser &&
            activeUser.accountName &&
            setDmpBalance &&
            !dmpBalanceLoading &&
            !dmpBalanceFetched
        ) {
            setDmpBalanceLoading(true);

            fetchDmpBalance({ account: activeUser.accountName })
                .then((balance) => setDmpBalance(balance))
                .catch((e) => {
                    setDmpBalance(0);

                    toast.error(e.message);
                })
                .finally(() => setDmpBalanceLoading(false));
        }
    }, [activeUser, dmpBalanceLoading, setDmpBalance, dmpBalanceFetched]);

    useEffect(() => {
        if (
            activeUser &&
            activeUser.accountName &&
            setTransactionInfo &&
            !transactionInfoFetched &&
            !transactionInfoLoading
        ) {
            setTransactionInfoLoading(true);

            fetchTransactionHistory({ account: activeUser.accountName })
                .then((info) => setTransactionInfo(info))
                .catch((e) => {
                    setTransactionInfo([]);

                    toast.error(e.message);
                })
                .finally(() => setTransactionInfoLoading(false));
        }
    }, [
        activeUser,
        setTransactionInfo,
        transactionInfoFetched,
        transactionInfoLoading,
    ]);

    useEffect(() => {
        if (
            setStakingConfig &&
            !stakingConfigFetched &&
            !stakingConfigLoading
        ) {
            setStakingConfigLoading(true);

            fetchStakingConfig()
                .then((data) => setStakingConfig(data))
                .catch((e) => {
                    setStakingConfig([]);

                    toast.error(e.message);
                })
                .finally(() => setStakingConfigLoading(false));
        }
    }, [setStakingConfig, stakingConfigFetched, stakingConfigLoading]);

    useEffect(() => {
        if (
            !preLaunchFinishTimestampFetched &&
            !preLaunchFinishTimestampLoading &&
            setPreLaunchFinishTimestamp
        ) {
            setPreLaunchFinishTimestampLoading(true);

            fetchPreLaunchFinishTimestamp()
                .then((data) => setPreLaunchFinishTimestamp(data))
                .catch((e) => {
                    setPreLaunchFinishTimestamp(0);

                    toast.error(e.message);
                })
                .finally(() => setPreLaunchFinishTimestampLoading(false));
        }
    }, [
        preLaunchFinishTimestampFetched,
        preLaunchFinishTimestampLoading,
        setPreLaunchFinishTimestamp,
    ]);

    return (
        <div
            className={cn('app-wrapper', {
                'app-wrapper--with-sidebar': sidebar,
                not_auth: !isAuthenticated,
                auth: isAuthenticated && !showWelcome,
                success:
                    userData &&
                    isAuthenticated &&
                    userItemsFetched &&
                    showWelcome,
                shelter,
            })}
            onClick={() => {
                if (!toggleRareFilter && !toggleTermFilter) return;

                toggleRareFilterHandler(false);
                toggleTermFilterHandler(false);
            }}
        >
            {routes}
            <Modal />
            <StakingModal />
            <AffiliateModal />

            <ToastContainer
                containerId="notifications"
                toastClassName="notifications__toast"
                bodyClassName="notifications__body"
                position="bottom-left"
                autoClose={7000}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                hideProgressBar
            />
        </div>
    );
};

export default AppContent;

export * from './wax.constants';
export * from './page.constants';
export * from './rarity.constants';
export * from './staking.constants';
export * from './filter.constants';
export * from './sorting.constants';
export * from './contract.constants';

export const CURRENCIES = {
    DMP: 'dmp',
    WAX: 'wax',
};

export const CURRENCY_TYPE = {
    [CURRENCIES.DMP]: 'DMP',
    [CURRENCIES.WAX]: 'WAX',
};

export const TOKEN_TYPE = {
    WAX: 0,
    DMP: 1,
};

export const CURRENCY = {
    [TOKEN_TYPE.WAX]: 'WAX',
    [TOKEN_TYPE.DMP]: 'DMP',
};

import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Alert, ShowNFTButton } from '../../Components';
import { Button } from '../../Components/Ui/v2';
import { useApp } from '../../Data/AppContext';
import { countDmpToClaim } from '../../Helpers';
import { MainPageMonitorLinks } from './MainPageMonitorLinks';

export const MainPageContent = () => {
    const {
        stakedList,
        shelter,
        scrollShelter,
        authData,
        preLaunchFinishTimestamp,
    } = useApp();
    const [dmpToClaim, setDmpToClaim] = useState('0.0000');

    useEffect(() => {
        setDmpToClaim(
            countDmpToClaim({
                stakedItems: stakedList,
                preLaunchFinishTimestamp,
            })
        );
    }, [stakedList, preLaunchFinishTimestamp]);

    return (
        <div
            className={cn('main-screen', {
                shelter,
                scroll__shelter: scrollShelter,
            })}
        >
            <Alert
                link="/MyStats"
                type="auth"
                caption="DMP TO COLLECT"
                text={Number(dmpToClaim).toFixed(4)}
                button={
                    <Button
                        className="hide-mobile"
                        style={{
                            minWidth: '180px',
                            marginTop: '8px',
                        }}
                        size="l"
                        use="secondary"
                    >
                        My Operations
                    </Button>
                }
            />

            <ShowNFTButton />

            {authData?.is_admin && (
                <MainPageMonitorLinks className="main-screen__monitor-links" />
            )}

            <div className="hide-desktop navigation-button">
                <NavLink to="/NFTStaking">
                    <Button
                        className="navigation-button__button"
                        size="l"
                        use="secondary"
                    >
                        NFT Staking
                    </Button>
                </NavLink>
                <NavLink to="/MyStats">
                    <Button
                        className="navigation-button__button"
                        size="l"
                        use="secondary"
                    >
                        My Operations
                    </Button>
                </NavLink>
                {authData?.is_admin && (
                    <>
                        <NavLink to="/Auction">
                            <Button
                                className="navigation-button__button"
                                size="l"
                                use="secondary"
                            >
                                Auctions
                            </Button>
                        </NavLink>
                        <NavLink to="/Store">
                            <Button
                                className="navigation-button__button"
                                size="l"
                                use="secondary"
                            >
                                Store
                            </Button>
                        </NavLink>
                    </>
                )}
            </div>
        </div>
    );
};

import React from 'react';
import { RadioGroup } from '../../../../Components/Ui/v2';
import { FiltersTitle } from '../../../../Components/Ui/v2/FiltersTitle';
import { useAuctionStore } from '../../store/useAuctionStore';
import { TOKEN_TYPE } from '../../../../Constants';

const COIN_FILTER = [
    { value: TOKEN_TYPE.DMP, name: 'DMP' },
    { value: TOKEN_TYPE.WAX, name: 'WAX' },
];

const Filters = () => {
    const filters = useAuctionStore((state) => state.filters);
    const setFilter = useAuctionStore((state) => state.setFilter);

    const handleChangeCoin = (value) => {
        setFilter({ type: 'coin', value });
    };

    return (
        <div className="auction-filters">
            <FiltersTitle className="auction-filters__label" />

            <RadioGroup
                value={filters.coin}
                onChange={handleChangeCoin}
                items={COIN_FILTER}
                name="coin"
                label="Coin"
            />
        </div>
    );
};

export default Filters;

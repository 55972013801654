import { CONTRACT_NAMES } from '../Constants';
import { signTransaction } from '../Helpers';

export const buyPackDMP = async ({ activeUser, lotId, quantity }) => {
    return await signTransaction({
        activeUser,
        account: CONTRACT_NAMES.tokn,
        action: 'transfer',
        data: {
            from: activeUser.accountName,
            to: CONTRACT_NAMES.market,
            memo: lotId,
            quantity: `${quantity.toFixed(8)} DMP`,
        },
    });
};

export const buyPackWAX = async ({ activeUser, lotId, quantity }) => {
    return await signTransaction({
        activeUser,
        account: CONTRACT_NAMES.eosioToken,
        action: 'transfer',
        data: {
            from: activeUser.accountName,
            to: CONTRACT_NAMES.market,
            memo: lotId,
            quantity: `${quantity.toFixed(8)} WAX`,
        },
    });
};

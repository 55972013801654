import { TOKEN_TYPE, CURRENCIES } from '../Constants';

export * from './initEndpoints';
export * from './chain.helper';
export * from './staking.helper';
export * from './atomicApi.helper';
export * from './cards.helper';

export const formatNumber = (value, options = {}) =>
    Intl.NumberFormat('en', options).format(value);

export const getWaxCount = (price) => price / 100000000;

export const getDmpCount = (price) => price / 100000000;

export const getBalanceCount = (priceType, price) => {
    const getBalance =
        +priceType === TOKEN_TYPE.WAX || priceType === CURRENCIES.WAX
            ? getWaxCount
            : getDmpCount;

    return getBalance(price);
};

export const pluralize = (count, text) => (count > 1 ? `${text}s` : text);

import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { countDmpToClaim } from '../../Helpers';
import { useApp } from '../../Data/AppContext';

import { ReactComponent as DmpIcon } from '../../assets/img/icons/dmp_icon.svg';
import { ReactComponent as InfoIcon } from '../../assets/img/icons/icon.svg';
import { Button } from '../Ui/v2/Button';

const EarnedBlock = () => {
    const { stakedList, showPopupHandler, preLaunchFinishTimestamp } = useApp();

    const [showFaq, setShowFaq] = useState(false);
    const [dmpToStake, setDmpToStake] = useState('0.0000');

    useEffect(() => {
        setDmpToStake(
            countDmpToClaim({
                stakedItems: stakedList,
                preLaunchFinishTimestamp,
            })
        );
    }, [stakedList, preLaunchFinishTimestamp]);

    const showInfo = (value) => {
        setShowFaq(value);
    };

    return (
        <div className="sidebar_block earned_block">
            <div className="block_title">
                Uncollected DMP
                <span
                    className="info_icon"
                    onMouseOver={() => showInfo('dmp')}
                    onMouseOut={() => showInfo(false)}
                >
                    <InfoIcon />
                </span>
                <div
                    className={classnames('info_popup', {
                        show: showFaq === 'dmp',
                    })}
                >
                    DMP rewards since the last collect.
                </div>
            </div>

            <div className="block_information">
                <div className="information_item">
                    <div className="information_value">
                        <div className="value">
                            <DmpIcon className="icon" /> {dmpToStake}
                        </div>
                    </div>
                </div>
            </div>

            <div className="button_block">
                <Button
                    size="sm"
                    use="secondary"
                    onClick={() => showPopupHandler('claim')}
                >
                    Collect
                </Button>
            </div>
        </div>
    );
};

export default EarnedBlock;

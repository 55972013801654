import { DROP_TYPE } from '../Containers/StorePage/constants';
import { getMs } from './utils';

const GIF_IMAGES = [
    314105, 314134, 314712, 314721, 314749, 314748, 314747, 314744, 314743,
    314738, 314739, 492749,
];

export const FORCE_ASSET_RARITY = {
    176451: 'Legendary',
    314739: 'Legendary',
};

export const TEST_NET_ASSETS_ID_MAP = {
    177577: 314738,
    177576: 314737,
    177575: 314735,
    177574: 314734,
    177573: 314733,
    177453: 314721,
    177452: 314719,
    177451: 314716,
    177450: 314715,
    177449: 314714,
    177448: 314712,
    177447: 314711,
    177446: 314710,
    177445: 314707,
    177444: 314706,
    176896: 314134,
    176895: 314130,
    176894: 314124,
    176891: 314117,
    176885: 314112,
    176879: 314105,
    176874: 314096,
    176873: 314090,
    176872: 314085,
    176871: 314084,
    176450: 314749,
    176449: 314748,
    176448: 314747,
    176447: 314744,
    176446: 314743,
    176451: 314739,
    411113: 492749,
    411112: 492748,
    411111: 492746,
    411110: 492745,
    411109: 492803,
    417446: 514593,
    423071: 514913,
    423072: 514915,
    423073: 514916,
    423074: 514917,
    423075: 514918,
    423037: 514924,
    423036: 514923,
    423016: 514922,
    423015: 514920,
    422959: 514925,
    423863: 514947,
    423864: 514948,
    423866: 514949,
    423867: 514950,
    424148: 514951,
    423858: 514942,
    423859: 514943,
    423860: 514944,
    423861: 514945,
    423862: 514946,
    423082: 514927,
    423845: 514929,
    423847: 514934,
    423850: 514939,
    423851: 514941,
    604156: 645274,
};

export const getImagePath = (templateId) => {
    const ID = TEST_NET_ASSETS_ID_MAP[templateId] || templateId;
    const isGif = GIF_IMAGES.some((gifId) => gifId === ID);
    const ext = isGif ? 'gif' : 'png';

    return `/img/cards/${ID}.${ext}`;
};

export const filterByCoin = (data, coin) =>
    coin === 'all' ? data : data?.filter((card) => card.price_type === coin);

export const filterByRarity = (data, rarity) =>
    rarity
        ? data?.filter((card) => card.rarity === rarity || !card.rarity)
        : data;

export const getIsSaleCardActive = ({
    card,
    isWLRoundFinished,
    wlStart = 0,
    wlFinish = 0,
}) => {
    const now = Date.now();

    return card.drop_type === DROP_TYPE.Whitelist
        ? now > wlStart && !isWLRoundFinished && now < getMs(card.expired_at)
        : now > wlFinish && now < getMs(card.expired_at);
};

const filterByActive = ({
    data,
    isOnlyActiveShowing,
    isWLRoundFinished,
    wlStart,
    wlFinish,
}) =>
    isOnlyActiveShowing
        ? data.filter((card) => {
              if (isWLRoundFinished) {
                  // we use isSaleEnd only in Drop Page
                  return getIsSaleCardActive({
                      card,
                      isWLRoundFinished,
                      wlStart,
                      wlFinish,
                  });
              }

              const itemsLeft = card?.asset_ids.length || 0;
              const isOutOfStock = itemsLeft === 0;

              return !isOutOfStock;
          })
        : data;

export const filterCards = ({
    data,
    coin,
    rarity,
    isOnlyActiveShowing,
    isWLRoundFinished,
    wlStart,
    wlFinish,
}) => {
    if (!data) {
        return undefined;
    }

    let result = data;
    result = filterByCoin(result, coin);
    result = filterByRarity(result, rarity);
    result = filterByActive({
        data: result,
        isOnlyActiveShowing,
        isWLRoundFinished,
        wlStart,
        wlFinish,
    });

    return result;
};

import { TOKEN_TYPE } from '../../../Constants';
import { buyPackDMP, buyPackWAX } from '../../../Services/store.service';

export const buyPack = async ({ activeUser, card, quantity }) => {
    switch (card.price_type) {
        case TOKEN_TYPE.DMP:
            return await buyPackDMP({ activeUser, quantity, lotId: card.id });
        case TOKEN_TYPE.WAX:
            return await buyPackWAX({ activeUser, quantity, lotId: card.id });
        default:
            return null;
    }
};

import React, { useMemo } from 'react';

import { Divider } from '../../../Components/Ui/v2/Divider';
import { ReactComponent as WaxIcon } from '../../../assets/img/icons/wax_icon_orange.svg';
import { ReactComponent as DmpIcon } from '../../../assets/img/icons/dmp_icon.svg';
import { AuctionSidebarContainer } from './AuctionSidebarContainer';
import { isAuctionFinished } from '../utils';
import { formatNumber, getDmpCount, getWaxCount } from '../../../Helpers';
import { useUser } from '../../../Hooks/useUser';
import { getMyAuctionsCards } from '../hooks/helpers';
import { TOKEN_TYPE } from '../../../Constants';

export const MyAuctionStatsSidebar = ({ data = [] }) => {
    const { accountName } = useUser() ?? {};

    const { currentAuctions, finishedAuctions } = getMyAuctionsCards(
        data
    ).reduce(
        (result, auction) => {
            if (isAuctionFinished(auction))
                result.finishedAuctions.push(auction);
            else result.currentAuctions.push(auction);

            return result;
        },
        { currentAuctions: [], finishedAuctions: [] }
    );

    const winningCount = useMemo(
        () =>
            currentAuctions.filter(
                (auction) => auction.current_winner === accountName
            ).length,
        [currentAuctions, accountName]
    );

    const currentCount = {
        all: currentAuctions.length,
        winning: winningCount,
        losing: currentAuctions.length - winningCount,
    };

    const wonCount = useMemo(
        () =>
            finishedAuctions.filter(
                (auction) => auction.current_winner === accountName
            ).length,
        [finishedAuctions, accountName]
    );

    const finishedCount = {
        all: finishedAuctions.length,
        winning: wonCount,
        losing: finishedAuctions.length - wonCount,
    };

    const { totalWaxBids, totalVDmpBids } = useMemo(
        () =>
            currentAuctions.reduce(
                (result, auction) => {
                    result[
                        auction.price_type === TOKEN_TYPE.WAX
                            ? 'totalWaxBids'
                            : 'totalDmpBids'
                    ] +=
                        auction.current_winner === accountName
                            ? +auction.current_price
                            : 0;

                    return result;
                },
                { totalWaxBids: 0, totalVDmpBids: 0 }
            ),
        [currentAuctions, accountName]
    );

    return (
        <AuctionSidebarContainer>
            <p className="auction-page-sidebar-stats__title">
                My Auction Stats
            </p>
            <Divider style={{ margin: '25px 0 12px' }} />
            <div className="auction-page-sidebar-stats__item">
                <span>Current auctions</span>
                <b>{currentCount.all}</b>
            </div>
            <div
                className="auction-page-sidebar-stats__item"
                style={{ marginTop: 12 }}
            >
                <span>Winning</span>
                <b>{currentCount.winning}</b>
            </div>
            <div
                className="auction-page-sidebar-stats__item"
                style={{ marginTop: 6 }}
            >
                <span>Losing</span>
                <b>{currentCount.losing}</b>
            </div>
            <Divider style={{ margin: '16px 0 12px' }} />
            <div className="auction-page-sidebar-stats__item">
                <span>Finished auctions</span>
                <b>{finishedCount.all}</b>
            </div>
            <div
                className="auction-page-sidebar-stats__item"
                style={{ marginTop: 12 }}
            >
                <span>Won</span>
                <b>{finishedCount.winning}</b>
            </div>
            <div
                className="auction-page-sidebar-stats__item"
                style={{ margin: '6px 0 38px' }}
            >
                <span>Lost</span>
                <b>{finishedCount.losing}</b>
            </div>
            <p className="auction-page-sidebar-stats__title">My current bids</p>
            <Divider style={{ margin: '6px 0 12px' }} />
            <div className="auction-page-sidebar-stats__total">
                <DmpIcon className="auction-page-sidebar-stats__total-icon" />
                <span>{formatNumber(getDmpCount(totalVDmpBids))}DMP</span>
                <WaxIcon className="auction-page-sidebar-stats__total-icon" />
                <span>{formatNumber(getWaxCount(totalWaxBids))} WAX</span>
            </div>
        </AuctionSidebarContainer>
    );
};

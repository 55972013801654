import React, { useEffect, memo } from 'react';

import { Counter } from '../Counter';
import { Button } from '../../../../Components/Ui/v2';
import { formatNumber, getBalanceCount } from '../../../../Helpers';
import { CURRENCY_TYPE, TOKEN_TYPE, CURRENCIES } from '../../../../Constants';
import { useCounter } from '../useCounter';
import { useAffiliate } from '../../../../Hooks/useModal';
import { useApp } from '../../../../Data/AppContext';
import { ButtonForCallToAuth } from './ButtonForCallToAuth';

export const itemsToBuyCount = 'itemsToBuyCount';

export const BuySection = memo(
    ({ isFormDisabled, card, itemsLeftUserCount }) => {
        const { isAuthenticated } = useApp();
        const itemsLeft = Math.min(itemsLeftUserCount, card?.asset_ids.length);
        const countFromSessionStorage = Number(
            sessionStorage.getItem(itemsToBuyCount)
        );
        const { count, setCount, increment, decrement } = useCounter({
            max: itemsLeft,
            min: 1,
            initial: countFromSessionStorage || 1,
        });
        const priceType =
            card.price_type === TOKEN_TYPE.DMP
                ? CURRENCIES.DMP
                : CURRENCIES.WAX;
        const totalPrice = card.price_per_asset * count;
        const currency = CURRENCY_TYPE[priceType];
        const { showAffiliationPopup } = useAffiliate();
        const formattedAmount = formatNumber(
            getBalanceCount(priceType, totalPrice)
        );

        useEffect(() => {
            sessionStorage.setItem(itemsToBuyCount, count);
        }, [count]);
        const handleInput = (event) => {
            setCount(event.currentTarget.value);
        };
        const handleSubmit = async (event) => {
            event.preventDefault();
            showAffiliationPopup('confirm');
        };
        const buttonText = `Buy (${formattedAmount} ${currency})`;
        return (
            <form
                className="store-product-page-options-form"
                onSubmit={handleSubmit}
            >
                <div className="store-product-page-options-counter">
                    <Counter
                        size="l"
                        onIncrement={increment}
                        onDecrement={decrement}
                        onInput={handleInput}
                        value={count}
                        disabled={isFormDisabled && isAuthenticated}
                    />
                </div>
                {isAuthenticated ? (
                    <Button
                        className="store-card__button"
                        type="submit"
                        size="l"
                        disabled={isFormDisabled}
                    >
                        {buttonText}
                    </Button>
                ) : (
                    <ButtonForCallToAuth text={buttonText} />
                )}
            </form>
        );
    }
);
